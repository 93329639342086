$(document).ready(function(){

  if ($('.nip-formatting-js').length > 0) {
    var cleave = new Cleave('.nip-formatting-js', {
      delimiter: '-',
      blocks: [3, 3, 2, 2],
      uppercase: true
    });
  }

  if ($('.zip-code-formatting-js').length > 0) {
    var cleave = new Cleave('.zip-code-formatting-js', {
      delimiter: '-',
      blocks: [2, 3],
      uppercase: true
    });
  }

  if ($('.second-zip-code-formatting-js').length > 0) {
    var cleave = new Cleave('.second-zip-code-formatting-js', {
      delimiter: '-',
      blocks: [2, 3],
      uppercase: true
    });
  }
});
