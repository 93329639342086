// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


var jQuery = require("jquery")

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require('bootstrap')
require('bootstrap-datepicker')
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.pl.min.js')
require('bootstrap-select')
require('bootstrap-select/dist/js/i18n/defaults-pl_PL.min.js')
require('cleave.js/dist/cleave.min.js')

require('packs/masks.js')

// TODO: https://github.com/nosir/cleave.js/issues/341
window.currency_mask2 = currency_mask2
window.currency_mask3 = currency_mask3

window.fire_mask_for_currency = function(id) {
  var cleave = new Cleave(id, {
    numeral: true,
    numeralThousandsGroupStyle: 'thousand',
    // numeralDecimalMark: ',', // TODO: przecinek zamiast kropki
    delimiter: ' '
  });
}

$(document).ready(function () {
  $('#save_draft').click(function (e){
    var contract_warranty_value = $("#contract_warranty_value").val();
    var contract_calculation_name = $("#contract_calculation_name").val();
    var alert_info = "Należy uzupełnić wymagane dane oznaczone na czerwono:\n"
    var scoped_value_counter = false
    var scoped_start_date_counter = false
    var scoped_end_date_counter = false
    var contract_warranty_value_counter = false
    var contract_calculation_name_counter = false
    $(".scoped_value").each(function (index) {
      if ($(this).val().length == 0) {
        $(this).addClass('is-invalid');
        scoped_value_counter = true;
        alert_info += "- Wartość poręczenia (w wierszu " + (index + 1) + ")\n"
      } else {
        $(this).removeClass('is-invalid');
      }
    });
    $(".scoped_start_date").each(function (index) {
      if ($(this).val().length == 0) {
        $(this).addClass('is-invalid');
        scoped_start_date_counter = true;
        alert_info += "- Data rozpoczęcia poręczenia (w wierszu " + (index + 1) + ")\n"
      } else {
        $(this).removeClass('is-invalid');
      }
    });
    $(".scoped_end_date").each(function (index) {
      if ($(this).val().length == 0) {
        scoped_end_date_counter = true;
        $(this).addClass('is-invalid');
        alert_info += "- Data zakończenia poręczenia (w wierszu " + (index + 1) + ")\n"
      } else {
        $(this).removeClass('is-invalid');
      }
    });
    if (contract_warranty_value.length == 0) {
      $("#contract_warranty_value").addClass('is-invalid');
      contract_warranty_value_counter = true;
      alert_info += "- Wartość poręczeń musi być wypełnione\n"
    } else {
      $("#contract_warranty_value").removeClass('is-invalid');
    }
    if (contract_calculation_name.length == 0) {
      $("#contract_calculation_name").addClass('is-invalid');
      contract_calculation_name_counter = true;
      alert_info += "- Nazwa wersji roboczej musi być wypełnione\n"
    } else {
      $("#contract_calculation_name").removeClass('is-invalid');
    }
    if (contract_warranty_value_counter || contract_calculation_name_counter || scoped_value_counter || scoped_end_date_counter || scoped_start_date_counter) {
      e.preventDefault();
      alert(alert_info);
    }
  });

  $('#check_commission').click(function (e) {
    e.preventDefault();
    var form_data = $(".col-sm-10 form :input[name!='_method']").serialize();
    var actionUrl = $('#check_commission').attr('href');
    var contract_warranty_value = $("#contract_warranty_value").val();
    var alert_info = "Należy uzupełnić wymagane dane oznaczone na czerwono:\n"
    var scoped_value_counter = false
    var scoped_start_date_counter = false
    var scoped_end_date_counter = false
    var contract_warranty_value_counter = false
    $(".scoped_value").each(function (index) {
      if ($(this).val().length == 0) {
        $(this).addClass('is-invalid');
        scoped_value_counter = true;
        alert_info += "- Wartość poręczenia (w wierszu " + (index + 1) + ")\n"
      } else {
        $(this).removeClass('is-invalid');
      }
    });
    $(".scoped_start_date").each(function (index) {
      if ($(this).val().length == 0) {
        $(this).addClass('is-invalid');
        scoped_start_date_counter = true;
        alert_info += "- Data rozpoczęcia poręczenia (w wierszu " + (index + 1) + ")\n"
      } else {
        $(this).removeClass('is-invalid');
      }
    });
    $(".scoped_end_date").each(function (index) {
      if ($(this).val().length == 0) {
        scoped_end_date_counter = true;
        $(this).addClass('is-invalid');
        alert_info += "- Data zakończenia poręczenia (w wierszu " + (index + 1) + ")\n"
      } else {
        $(this).removeClass('is-invalid');
      }
    });
    if (contract_warranty_value.length == 0) {
      $("#contract_warranty_value").addClass('is-invalid');
      contract_warranty_value_counter = true;
      alert_info += "- Wartość poręczeń musi być wypełnione\n"
    } else {
      $("#contract_warranty_value").removeClass('is-invalid');
    }
    if (contract_warranty_value_counter || scoped_value_counter || scoped_end_date_counter || scoped_start_date_counter) {
      alert(alert_info);
    } else {
      $.ajax({
        type: 'POST',
        method: 'POST',
        dataType: 'json',
        url: actionUrl,
        data: form_data, // serializes the form's elements.
        success: function (data) {
          // domyslny procent
          $('#contract_std_commission_for_prepare').val(data['std_commission_for_prepare']);
          $('#contract_std_commission_for_period').val(data['std_commission_for_period']);
          $('#contract_std_prepare_percent').val(data['std_prepare_percent']);
          $('#contract_std_period_percent').val(data['std_period_percent']);
          $('#contract_std_commission_value').val(data['std_commission_value']);
          $('#contract_commission_for_prepare').val(data['commission_for_prepare']);
          $('#contract_commission_for_period').val(data['commission_for_period']);
          $('#contract_commission_value').val(data['commission_value']);
          $('#contract_old_commission_value').val(data['old_commission_value']);
          $('#contract_prepare_percent').val(data['prepare_percent']);
          $('#contract_period_percent').val(data['period_percent']);
          $('#period').html(data['period']);
          if (data['period'] === '') {
            alert('uzupełnij wymagane dane');
            return;
          }

          // formating commission value
          fire_mask_for_currency('#contract_std_commission_for_prepare');
          fire_mask_for_currency('#contract_std_commission_for_period');
          fire_mask_for_currency('#contract_std_commission_value');
          fire_mask_for_currency('#contract_commission_for_prepare');
          fire_mask_for_currency('#contract_commission_for_period');
          fire_mask_for_currency('#contract_commission_value');

          $("#calculation_save").show();
        }
      });
    }
  });

      $('.datepicker').datepicker({
        format: 'yyyy-mm-dd',
        language: 'pl'
      }).on('changeDate', function (e) {
        if ($('#contract_new_form_loan_sign_date').length > 0) {
          // for contract form - data wygasniecia umowy
          $('#contract_new_form_loan_sign_date').change();
        }
        if ($('#contract_detail_loan_sign_date').length > 0) {
          // for contract form
          $('#contract_detail_loan_sign_date').change();
        }
        $(this).datepicker('hide');
      });
      ;
// dodana obsluga eventu, jak sie zmienia wartosc daty bez uzycia datepickera
      $('#contract_new_form_loan_sign_date, #contract_new_form_loan_end_date').change(function () {
        // for contract form - data wygasniecia umowy
        if (($(this).attr('id') == 'contract_new_form_loan_sign_date') || ($(this).attr('id') == 'contract_new_form_loan_end_date')) {
          contract_form_autofilling_inputs('contract_new_form');
        }
      });
// dodana obsluga eventu, jak sie zmienia wartosc daty bez uzycia datepickera
      $('#contract_detail_loan_sign_date, #contract_detail_loan_end_date').change(function () {
        // for contract form
        if (($(this).attr('id') == 'contract_detail_loan_sign_date') || ($(this).attr('id') == 'contract_detail_loan_end_date')) {
          contract_form_autofilling_inputs('contract_detail');
        }
      });

// search formsy
      $('.hide-search-form-js').click(function (e) {
        e.preventDefault();
        $(this).closest('form').slideUp();
        $('.show-search-form-js').show()
      });

      $('.show-search-form-js').click(function (e) {
        e.preventDefault();
        var href = $(this).data('href')
        $(href).slideDown();
        $(this).hide();
      });

// $('.search-form .input-group')
      $('.search-form .btn.btn-clear-js').click(function (e) {
        e.preventDefault()
        var target = $(this).data('target');
        $(target).val('');
        if ($(target).hasClass('selectpicker')) {
          $(this).parent().find('.filter-option-inner-inner').text('Wybierz...');
          $(this).parent().find('button.btn.dropdown-toggle').addClass('bs-placeholder');
          $(this).parent().find('button.btn.dropdown-toggle').attr('title', 'Wybierz...');
        }
      });

      $('.clean-all-inputs-js').click(function (e) {
        e.preventDefault();
        var target = $(this).data('target');
        var deselect_target = $(this).data('deselect-target');
        $(target).find('.btn-clear-js').each(function (e) {
          $(this).click();
        });
        if ($(deselect_target).length > 0) {
          $(deselect_target).click();
        }
      });

      $('.simple_form .input-group-append').click(function (e) {
        $(this).parent().find('input').focus();
        // TODO: do sprwdzenia - event selectpickera
        // if ($(this).parent().find('button').length == 1) {
        //   $(this).parent().find('button').click();
        // } else {
        //   $(this).parent().find('input').focus();
        // }
      });

      $('.search-form .input-group-append').click(function (e) {
        // $(this).parent().find('input').click();
        $(this).parent().find('input').focus();
      });

      $('[data-toggle="tooltip"]').tooltip();

// global functions
      close_element();
      show_popup();

// contract form
      show_company_data_on_company_select();
      show_company_data_on_contract_reload();
      check_nwr_application_number();
// show_products_on_program_select(); // juz nieuzywane - produkty wymierane sa w pierwszym kroku
// contract_form_autofilling_inputs();
      contract_form_percentage_fill();
// contract_calculate_percentage(); // zakomentowano zeby nie nadpisywac danej z bazy danych na wczytanie strony
// contract_form_number_to_currency();
      company_save_with_new_version();
      show_appendix_for_text_input();
      create_company_remotely();
      chosing_close_or_cancele_state();
      product_provision_setting();
      mask_for_currencies();

// bid nond contract
      bid_bond_warranty_end_date_fill();
      create_client_remotely();
      set_commission_for_bid_bond();
      set_reward_value_for_bid_bond();

// bid nond package
      clear_name_for_draft_status();

// statements
      show_generate_statements_popup();
      showing_previous_statements();
      confirming_statement_fields();
      show_statement_input();
      statement_inputs_confirm_by_enter();
      update_statement_with_send_params();
      get_edition_row_for_statement();

// case_note
      case_note_filling_dates();

// work_memo
      work_memo_text_fields_inputs_insert();
      work_memo_definition_select();
      work_memo_item_header();
      work_memo_item_trash_button();
      work_memo_item_removing();

// sebmit reports from select for reserves
      report_select();

// dictionaries
      adding_contributions_in_program();
      create_contrbutions_hash();
      add_new_thershold();
// testing contribution fields
      contribution_fields();
      provisions_showing_months();
    }
  )
  ;

// closing function
  function close_element() {
    $('.close-js').click(function (e) {
      e.preventDefault();
      target = $(this).data('target');
      $(target).hide();
    });
  }

  function show_popup() {
    $('.show-popup-js').click(function (e) {
      e.preventDefault();
      target = $(this).data('target')
      $(target).show();
    });
  }

  function add_days_to_date(date, days) {
    date_array = date.split('-');
    parsed_date = new Date(date_array[0], parseInt(date_array[1]) - 1, date_array[2]);
    parsed_date.setDate(parsed_date.getDate() + days);
    // month = parsed_date.getUTCMonth() < 9 ? '0' + (parsed_date.getUTCMonth() + 1) : (parsed_date.getUTCMonth() + 1);
    // day = parsed_date.getUTCDate() < 10 ? '0' + parsed_date.getUTCDate() : parsed_date.getUTCDate();
    // stringed_date = parsed_date.getUTCFullYear() + '-' + month + '-' + day;
    // return stringed_date;
    return parsed_date;
  }

// appendixes in forms
  function show_appendix_for_text_input() {
    $('.with-appendix-js').each(function () {
      target = $(this).data('appendix');
      clone = $(target).clone();
      $(this).find('.input-group-append .input-group-text').append(clone.html());
      $(target).remove();
    });
  }

// contracts
  function show_company_data_on_company_select() {
    // for contracts
    if ($("#contract_new_form_company_id").length > 0) {
      $("#contract_new_form_company_id").on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        company_id = $("#contract_new_form_company_id").val();
        href = $("#contract_new_form_company_id").data('href');
        if (company_id != '') {
          $.get(href, {_method: 'get', remote: true, id: company_id}, null, "script");
        }
      });
    }
    // for annexes
    if ($("#annex_form_company_id").length > 0) {
      $("#annex_form_company_id").on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        company_id = $("#annex_form_company_id").val();
        href = $("#annex_form_company_id").data('href');
        if (company_id != '') {
          $.get(href, {_method: 'get', remote: true, id: company_id}, null, "script");
        }
      });
    }
    // for bid bonds
    if ($("#bid_bond_contract_form_bid_bond_package_id").length > 0) {
      $("#bid_bond_contract_form_bid_bond_package_id").on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        package_id = $("#bid_bond_contract_form_bid_bond_package_id").val();
        href = $("#bid_bond_contract_form_bid_bond_package_id").data('href');
        if (package_id != '') {
          $.get(href, {_method: 'get', remote: true, id: package_id}, null, "script");
        }
      });
    }
    // if ($("#contract_company_id").length > 0) {
    //   $("#contract_company_id").on('changed.bs.select', function(e, clickedIndex, isSelected, previousValue) {
    //     company_id = $("#contract_company_id").val();
    //     href = $("#contract_company_id").data('href');
    //     if (company_id != '') {
    //       $.get(href, {_method:'get', remote: true, id: company_id}, null, "script");
    //     }
    //   });
    // }
  }

  function show_company_data_on_contract_reload() {
    // TODO: byc moze do wczytania na widoku?
    if ($("#contract_new_form_company_id").length > 0) {
      $("#contract_new_form_company_id").on('loaded.bs.select', function (e) {
        company_id = $("#contract_new_form_company_id").val();
        href = $("#contract_new_form_company_id").data('href');
        if (company_id != '') {
          $.get(href, {_method: 'get', remote: true, id: company_id}, null, "script");
        }
      });
    }
    // for anenxes
    if ($("#annex_form_company_id").length > 0) {
      $("#annex_form_company_id").on('loaded.bs.select', function (e) {
        company_id = $("#annex_form_company_id").val();
        href = $("#annex_form_company_id").data('href');
        if (company_id != '') {
          $.get(href, {_method: 'get', remote: true, id: company_id}, null, "script");
        }
      });
    }
    // for contract details edit
    if ($("#contract_detail_company_id").length > 0) {
      $("#contract_detail_company_id").on('loaded.bs.select', function (e) {
        company_id = $("#contract_detail_company_id").val();
        href = $("#contract_detail_company_id").data('href');
        if (company_id != '') {
          $.get(href, {_method: 'get', remote: true, id: company_id}, null, "script");
        }
      });
    }
    // for bid bonds
    if ($("#bid_bond_contract_form_bid_bond_package_id").length > 0) {
      $("#bid_bond_contract_form_bid_bond_package_id").on('loaded.bs.select', function (e) {
        package_id = $("#bid_bond_contract_form_bid_bond_package_id").val();
        href = $("#bid_bond_contract_form_bid_bond_package_id").data('href');
        if (package_id != '') {
          $.get(href, {_method: 'get', remote: true, id: package_id}, null, "script");
        }
      });
    }
    // if ($("#contract_company_id").length > 0) {
    //   $("#contract_company_id").on('loaded.bs.select', function(e) {
    //     company_id = $("#contract_company_id").val();
    //     href = $("#contract_company_id").data('href');
    //     if (company_id != '') {
    //       $.get(href, {_method:'get', remote: true, id: company_id}, null, "script");
    //     }
    //   });
    // }
  }

  function show_products_on_program_select() {
    if ($("#contract_new_form_program_id").length > 0) {
      $("#contract_new_form_program_id").on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        program_id = $("#contract_new_form_program_id").val();
        href = $("#contract_new_form_program_id").data('href');
        if (program_id != '') {
          $.get(href, {_method: 'get', remote: true, id: program_id}, function (data) {
            $('#contract_new_form_product_id').parent().parent().html(data.partial);
            $('#contract_new_form_product_id').selectpicker();
          }, 'json');
        }
      });
    }
    // // for bid bonds
    // if ($("#bid_bond_contract_form_program_id").length > 0) {
    //   $("#bid_bond_contract_form_program_id").on('changed.bs.select', function(e, clickedIndex, isSelected, previousValue) {
    //     program_id = $("#bid_bond_contract_form_program_id").val();
    //     href = $("#bid_bond_contract_form_program_id").data('href');
    //     if (program_id != '') {
    //       $.get(href, {_method:'get', remote: true, id: program_id}, function(data) {
    //         $('#bid_bond_contract_form_product_id').parent().parent().html(data.partial);
    //         $('#bid_bond_contract_form_product_id').selectpicker();
    //       }, 'json');
    //     }
    //   });
    // }
  }

// TODO: dodac dla aneksow i wadiow?
  function contract_form_autofilling_inputs(form_name) {
    loan_sign_date = $('#' + form_name + '_loan_sign_date').val();
    loan_end_date = $('#' + form_name + '_loan_end_date').val();
    // loan_sign_date = $('#contract_new_form_loan_sign_date').val();
    // loan_end_date = $('#contract_new_form_loan_end_date').val();
    // loan_sign_date = $('#contract_loan_sign_date').val();
    // loan_end_date = $('#contract_loan_end_date').val();

    if ((loan_sign_date != '') && (loan_end_date == '')) {
      // set only warranty sign date
      $('#' + form_name + '_warranty_sign_date').val(loan_sign_date);
      // $('#contract_new_form_warranty_sign_date').val(loan_sign_date);
      // $('#contract_warranty_sign_date').val(loan_sign_date);
    } else if ((loan_sign_date == '') && (loan_end_date != '')) {
      // set only warranty end date
      $('#' + form_name + '_warranty_end_date').val(loan_end_date);
      // $('#contract_new_form_warranty_end_date').val(loan_end_date);
      // $('#contract_warranty_end_date').val(loan_end_date);
    } else if ((loan_sign_date != '') && (loan_end_date != '')) {
      sign_arr = loan_sign_date.split('-');
      end_arr = loan_end_date.split('-');
      year_diff = end_arr[0] - sign_arr[0];
      months_diff = end_arr[1] - sign_arr[1];

      maturity = (year_diff * 12) + months_diff + 1;

      if (end_arr[1] <= 6) {
        var warranty_end_date = end_arr[0] + '-' + (parseInt(end_arr[1]) + 6) + '-' + end_arr[2];
      } else {
        var warranty_end_date = (parseInt(end_arr[0]) + 1) + '-' + (parseInt(end_arr[1]) + 6 - 12) + '-' + end_arr[2];
      }

      // set maturity
      $('#' + form_name + '_loan_maturity').val(maturity);
      // $('#contract_new_form_loan_maturity').val(maturity);
      // $('#contract_loan_maturity').val(maturity);
      // set warranty dates
      $('#' + form_name + '_warranty_sign_date').datepicker('update', loan_sign_date);
      $('#' + form_name + '_warranty_end_date').datepicker('update', warranty_end_date);
      // $('#contract_new_form_warranty_sign_date').datepicker('update', loan_sign_date);
      // $('#contract_new_form_warranty_end_date').datepicker('update', warranty_end_date);
      // $('#contract_warranty_sign_date').datepicker('update', loan_sign_date);
      // $('#contract_warranty_end_date').datepicker('update', warranty_end_date);
    }
  }

  function contract_form_percentage_fill() {
    $('#contract_new_form_loan_value').focusin(function (e) {
      fire_mask_for_currency('#' + $(this).attr('id'));
    }).focusout(function (e) {
      // round_value_to_two_decimal_places($(this));
      contract_calculate_percentage('contract_new_form');
    });
    $('#contract_new_form_warranty_value').focusin(function (e) {
      fire_mask_for_currency('#' + $(this).attr('id'));
    }).focusout(function (e) {
      // round_value_to_two_decimal_places($(this));
      contract_calculate_percentage('contract_new_form');
    });
    // in case of editing percentage value
    $('#contract_new_form_warranty_percentage').focusin(function (e) {
      fire_mask_for_currency('#' + $(this).attr('id'));
    }).focusout(function (e) {
      // round_value_to_two_decimal_places($(this));
      fire_mask_for_currency('#' + $(this).attr('id'));
    });
    ///// contract_detail
    $('#contract_detail_loan_value').focusin(function (e) {
      fire_mask_for_currency('#' + $(this).attr('id'));
    }).focusout(function (e) {
      // round_value_to_two_decimal_places($(this));
      contract_calculate_percentage('contract_detail');
    });
    $('#contract_detail_warranty_value').focusin(function (e) {
      fire_mask_for_currency('#' + $(this).attr('id'));
    }).focusout(function (e) {
      // round_value_to_two_decimal_places($(this));
      contract_calculate_percentage('contract_detail');
    });
    // in case of editing percentage value
    $('#contract_detail_warranty_percentage').focusin(function (e) {
      fire_mask_for_currency('#' + $(this).attr('id'));
    }).focusout(function (e) {
      // round_value_to_two_decimal_places($(this));
      fire_mask_for_currency('#' + $(this).attr('id'));
    });
  }

  function contract_calculate_percentage(form_name) {
    if (($('#' + form_name + '_loan_value').val() != '') && ($('#' + form_name + '_warranty_value').val() != '')) {
      loan_value = $('#' + form_name + '_loan_value').val().replaceAll(' ', '');
      warranty_value = $('#' + form_name + '_warranty_value').val().replaceAll(' ', '');
      percent = ((parseInt(warranty_value) * 100) / parseInt(loan_value)).toFixed(2);
      // percent = percent.replace('.', ',');
      $('#' + form_name + '_warranty_percentage').val(percent);
      fire_mask_for_currency('#' + form_name + '_warranty_percentage');
    }
    // if (($('#contract_new_form_loan_value').val() != '' ) && ($('#contract_new_form_warranty_value').val() != '' )) {
    //   loan_value = $('#contract_new_form_loan_value').val().replaceAll(' ','');
    //   warranty_value = $('#contract_new_form_warranty_value').val().replaceAll(' ','');
    //   percent = ((parseInt(warranty_value) * 100) / parseInt(loan_value)).toFixed(2);
    //   percent = percent.replace('.', ',');
    //   $('#contract_new_form_warranty_percentage').val(percent);
    // }
    //   if (($('#contract_loan_value').val() != '' ) && ($('#contract_warranty_value').val() != '' )) {
    //     loan_value = $('#contract_loan_value').val().replaceAll(' ','');
    //     warranty_value = $('#contract_warranty_value').val().replaceAll(' ','');
    //     percent = (parseInt(warranty_value) * 100) / parseInt(loan_value);
    //     $('#contract_warranty_percentage').val(percent.toFixed(1))
    //   }
  }

  function contract_form_number_to_currency() {
    $('.number_to_currency-js').each(function () {
      round_value_to_two_decimal_places($(this));
    });
    $('.number_to_currency-js').focusout(function () {
      round_value_to_two_decimal_places($(this));
    });
    $('.number_to_currency-scale-four-js').each(function () {
      round_value_to_four_decimal_places($(this));
    });
    $('.number_to_currency-scale-four-js').focusout(function () {
      round_value_to_four_decimal_places($(this));
    });
  }

  function mask_for_currencies() {
    currency_mask2('.number_to_currency-js');
    currency_mask2('.currency-mask2-js');
    currency_mask3('.currency-mask3-js');

    if ($('.search-form input[type="number"]').length > 0) {
      $('.search-form input[type="number"]').each(function () {
        $(this).attr('type', 'text');
        $(this).css('text-align', 'right');
        $(this).css('padding-right', '30px');
        $(this).siblings('.btn-clear-js').removeClass('doubled-appended-number').addClass('doubled-appended');
        var id = '#' + $(this).attr('id');
        fire_mask_for_currency(id);
      });
    }
  }

  function check_nwr_application_number() {
    $('#nwr_application_application_number').focusout(function () {
      number_value = $('#nwr_application_application_number').val();
      if (number_value != '') {
        if ($(this).parent().find('div.invalid-feedback').length == 0) {
          $(this).addClass('is-invalid');
          $(this).parent().append('<div class="invalid-feedback">Numer wniosku musi byc w formacie NUMER.ROK np 123.2023</div>');
        }
      }
    });
  }

  function fire_mask_for_currency(id) {
    currency_mask2(id);
  }

  function currency_mask2(selector) {
    $(selector).toArray().forEach(function(field){
      currency_mask(field, 2);
    });
  }

  function currency_mask3(selector) {
    $(selector).toArray().forEach(function(field){
      currency_mask(field, 3);
    });
  }

  function currency_mask(element, scale) {
    var cleave = new Cleave(element, {
      numeral: true,
      numeralDecimalScale: scale,
      numeralThousandsGroupStyle: 'thousand',
      // numeralDecimalMark: ',', // TODO: przecinek zamiast kropki
      delimiter: ' '
    });
  }

  function round_value_to_two_decimal_places(element) {
    currency_mask2('#' + element.attr('id'));
    // val = element.val();
    // if (val != '') {
    //   // new_val = parseFloat(parseFloat(val.replace(',','.')).toFixed(2)).toFixed(2).replace('.',',');
    //   new_val = parseFloat(parseFloat(val.replace(',','.')).toFixed(2)).toFixed(2).replace(',','.');
    //   element.val(new_val);
    // }
  }

  function round_value_to_four_decimal_places(element) {
    currency_mask3('#' + element.attr('id'));
  }

  function company_save_with_new_version() {
    $('.company-save-and-add-version-js').click(function (e) {
      e.preventDefault();
      result = confirm('Czy jesteś pewien?');
      if (result == true) {
        $('#new_version').val('true');
        $(this).closest('form').submit();
      }
    });
  }

  function create_company_remotely() {
    $('.popup #new_company input[type="submit"]').click(function (e) {
      e.preventDefault();
      form = $(this).closest('form');
      href = form.attr('action');
      form_alias = $('#new-company-popup').data('alias');
      // inputs = ['name', 'nip', 'regon', 'krs', 'employees_number', 'email', 'phone', 'legal_form_id', 'industry_id', 'company_size_id']
      company = {
        name: $('#company_name').val(),
        nip: $('#company_nip').val(),
        regon: $('#company_regon').val(),
        krs: $('#company_krs').val(),
        employees_number: $('#company_employees_number').val(),
        email: $('#company_email').val(),
        phone: $('#company_phone').val(),
        legal_form_id: $('#company_legal_form_id').val(),
        industry_id: $('#company_industry_id').val(),
        company_size_id: $('#company_company_size_id').val(),
        capital_group_id: $('#company_capital_group_id').val(),
        exposure: $('#company_exposure').val(),
        exposure_needs_verification: $('#company_exposure_needs_verification').is(":checked"),
        own_purposes_inv_in_malopolska: $('#company_own_purposes_inv_in_malopolska').is(":checked"),
        headquarter_address_attributes: {
          city: $('#company_headquarter_address_attributes_city').val(),
          zip_code: $('#company_headquarter_address_attributes_zip_code').val(),
          street_and_number: $('#company_headquarter_address_attributes_street_and_number').val(),
          area: $('#company_headquarter_address_attributes_area').val()
        },
        branch_address_attributes: {
          city: $('#company_branch_address_attributes_city').val(),
          zip_code: $('#company_branch_address_attributes_zip_code').val(),
          street_and_number: $('#company_branch_address_attributes_street_and_number').val(),
          area: $('#company_branch_address_attributes_area').val()
        }
      }
      $.post(href, {_method: 'post', remote: true, form_alias: form_alias, company: company}, function (data) {
        // console.log(data.outcome);
        if (data.outcome == 'success') {
          $('#new-company-popup .close.close-js').click();
          if ($('#contract_new_form_company_id').length > 0) {
            $('#contract_new_form_company_id').parent().parent().replaceWith(data.partial);
            $('#contract_new_form_company_id').selectpicker();
          } else if ($('#bid_bond_package_company_id').length > 0) {
            $('#bid_bond_package_company_id').parent().parent().replaceWith(data.partial);
            $('#bid_bond_package_company_id').selectpicker();
          } else if ($('#nwr_application_company_id').length > 0) {
            $('#nwr_application_company_id').parent().parent().replaceWith(data.partial);
            $('#nwr_application_company_id').selectpicker();
          }
        } else {
          $('#new-company-popup').replaceWith(data.partial);
          $('#new-company-popup').show();
          $('form#new_company .selectpicker').selectpicker();
          create_company_remotely();
          close_element();
        }
      }, "json")
    });
  }

  function chosing_close_or_cancele_state() {
    // LoanContract
    $("#contract_new_form_state").on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
      if ($(this).val() == 'closed' || $(this).val() == 'canceled') {
        if ($("#contract_new_form_close_date").val() == '') {
          $("#contract_new_form_close_date").datepicker('update', new Date);
        }
      } else {
        // $("#contract_new_form_close_date").datepicker('update', '');
      }
    });
    // BidBondContract
    $("#bid_bond_contract_form_state").on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
      if ($(this).val() == 'closed' || $(this).val() == 'canceled') {
        if ($("#bid_bond_contract_form_close_date").val() == '') {
          $("#bid_bond_contract_form_close_date").datepicker('update', new Date);
        }
      } else {
        // $("#bid_bond_contract_form_close_date").datepicker('update', '');
      }
    });
  }

  function product_provision_setting() {
    // contract
    if ($('#contract_new_form_commission_value').length > 0) {
      // wartosc poreczenia
      $('#contract_new_form_warranty_value').keyup(function (e) {
        get_product_provision('contract_new_form');
      });
      $('#contract_new_form_warranty_sign_date').change(function (e) {
        get_product_provision('contract_new_form');
      });
      $('#contract_new_form_warranty_end_date').change(function (e) {
        get_product_provision('contract_new_form');
      });
      // rodzaj splaty
      $("#contract_new_form_repayment_type").on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        get_product_provision('contract_new_form');
      });
    }

    // annex
    if ($('#annex_form_commission_value').length > 0) {
      $('#annex_form_warranty_value').change(function (e) {
        get_product_provision('annex_form');
      });
      $('#annex_form_warranty_sign_date').change(function (e) {
        get_product_provision('annex_form');
      });
      $('#annex_form_warranty_end_date').change(function (e) {
        get_product_provision('annex_form');
      });
      $("#annex_form_repayment_type").on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        get_product_provision('annex_form');
      });
    }
  }

  // class BidBondContract {
  class Contract {
    constructor(form_alias) {
      // this.form_alias = 'bid_bond_contract_form';
      this.form_alias = form_alias;
    }
    commission() {
      return $('#' + this.form_alias + '_commission_value');
    }
    set_commission_value(value) {
      return $('#' + this.form_alias + '_commission_value').val(value);
    }
    product_id() {
      return $('#' + this.form_alias + '_product_id').val();
    }
    warranty_value() {
      return $('#' + this.form_alias + '_warranty_value').val().replaceAll(' ', '');
    }
  }

  // to tylko dla wadiow i normalnych umow, dla nalezytego jest osobno liczona prowizja
  function get_product_provision(form_alias, experss = false) {
    const bid_bond_form = new Contract(form_alias);

    href = $('#' + form_alias + '_commission_value').data('href');
    product_id = bid_bond_form.product_id();
    warranty_value = bid_bond_form.warranty_value();
    repayment_type = form_alias == 'contract_new_form' ? $('#' + form_alias + '_repayment_type').val() : 'one_time';

    if ((form_alias == 'contract_new_form') || (form_alias == 'annex_form')) {
      // dla normalnych umow
      start_date = $('#' + form_alias + '_loan_sign_date').val();
      end_date = $('#' + form_alias + '_loan_end_date').val();
      months = months_difference(start_date, end_date) + 1; // to samo co maturity
      // $('#' + form_name + '_loan_maturity').val(maturity);

    } else if (form_alias == 'bid_bond_contract_form') {
      months = $('#bid_bond_contract_form_offer_duration').val() / 30;
    }

    // if (product_id && warranty_value && repayment_type && months && (experss || ($('#' + form_alias + '_commission_value').val() == ''))) {
    if (product_id && warranty_value && repayment_type && months) {
      $.get(href, {
        _method: 'get',
        remote: true,
        product_id: product_id,
        m: months,
        wv: warranty_value,
        rt: repayment_type
      }, function (data) {
        value = data['value'];
        if (($('#bid_bond_contract_form_express_warranty').length > 0) && ($('#bid_bond_contract_form_express_warranty').prop('checked'))) {
          var v2 = $('#bid_bond_contract_form_express_warranty').data('express-commission-multiplier');
          value = value * parseFloat(v2);
        }
        // $('#' + form_alias + '_commission_value').val(value);
        bid_bond_form.set_commission_value(value);
        fire_mask_for_currency('#' + form_alias + '_commission_value');
        calculate_reward_value_for_bid_bond();
      }, 'json');
    }

    // if (product_id && warranty_value && repayment_type && months && ( $('#' + form_alias + '_commission_value').val() == '')) {
    //   $.get(href, {_method:'get', remote: true, product_id: product_id, m: months, wv: warranty_value, rt: repayment_type}, function(data) {
    //     value = data['value'];
    //     if (($('#bid_bond_contract_form_express_warranty').length > 0 ) && ($('#bid_bond_contract_form_express_warranty').prop('checked') )) {
    //       value = 2 * value;
    //     }
    //     $('#' + form_alias + '_commission_value').val(value);
    //     fire_mask_for_currency('#' + form_alias + '_commission_value');
    //   }, 'json');
    // }
  }

var DateDiff = {
  inDays: function(d1, d2) {
    var t2 = d2.getTime();
    var t1 = d1.getTime();

    return Math.floor((t2-t1)/(24*3600*1000));
  },

  inWeeks: function(d1, d2) {
    var t2 = d2.getTime();
    var t1 = d1.getTime();

    return parseInt((t2-t1)/(24*3600*1000*7));
  },

  inMonths: function(d1, d2) {
    var d1Y = d1.getFullYear();
    var d2Y = d2.getFullYear();
    var d1M = d1.getMonth();
    var d2M = d2.getMonth();

    return (d2M+12*d2Y)-(d1M+12*d1Y);
  },

  inYears: function(d1, d2) {
    return d2.getFullYear()-d1.getFullYear();
  },

  calculated_months: function(d1, d2) {
    // TODO
    // console.log('calculated: ', (year_diff * 12) + months_diff);
    return 0;
  }
}


  function months_difference(start_date, end_date) {
    // requires format 2000-01-01
    sign_arr = start_date.split('-');
    end_arr = end_date.split('-');
    year_diff = end_arr[0] - sign_arr[0];
    months_diff = end_arr[1] - sign_arr[1];

    // d1 = new Date(start_date);
    // d2 = new Date(end_date);
    // console.log('inDays: ', DateDiff.inDays(d1, d2));
    // console.log('inMonths: ', DateDiff.inMonths(d1, d2));
    // console.log('inYears: ', DateDiff.inYears(d1, d2));
    // console.log('calculated: ', (year_diff * 12) + months_diff);

    return calculated_months = (year_diff * 12) + months_diff;
  }

// bid bond contract

  function bid_bond_warranty_end_date_fill() {
    if ($("#bid_bond_contract_form_offer_duration").length > 0) {
      $('#bid_bond_contract_form_offer_opening_date').change(function (e) {
        set_bid_bond_warranty_end_date();
      });

      $("#bid_bond_contract_form_offer_duration").on('change', function (e, clickedIndex, isSelected, previousValue) {
        set_bid_bond_warranty_end_date();
      });
    }
  }
  function set_bid_bond_warranty_end_date() {
    var opening_date = '';
    if ($("#bid_bond_contract_form_prolong_start_date").length > 0 && ($("#bid_bond_contract_form_prolong_start_date").val() != '')) {
      opening_date = $("#bid_bond_contract_form_prolong_start_date").val();
    } else {
      opening_date = $("#bid_bond_contract_form_offer_opening_date").val();
    }
    days = $("#bid_bond_contract_form_offer_duration").val();
    days = parseInt(days);
    if (Number.isNaN(days)) { return }

    // if (opening_date != '' && $("#bid_bond_contract_form_warranty_end_date").val() == '') {
    if (opening_date != '') {
      open_arr = opening_date.split('-');
      // console.log(open_arr);
      date = new Date(open_arr[0], parseInt(open_arr[1]) - 1, open_arr[2]);
      // console.log(date);
      date.setDate(date.getDate() + days);
      // console.log(date)
      month = date.getUTCMonth() < 9 ? '0' + (date.getUTCMonth() + 1) : (date.getUTCMonth() + 1);
      // console.log(month);
      day = date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate();
      // console.log(day);
      warranty_end_date = date.getUTCFullYear() + '-' + month + '-' + day;
      // console.log(warranty_end_date)
      $('#bid_bond_contract_form_warranty_end_date').datepicker('update', warranty_end_date);
    }
  }

  function create_client_remotely() {
    $('.popup #new_client input[type="submit"]').click(function (e) {
      e.preventDefault();
      form = $(this).closest('form');
      href = form.attr('action');
      form_alias = $('#new-client-popup').data('alias');
      client = {
        name: $('#client_name').val(),
        client_category_id: $('#client_client_category_id').val()
      }
      $.post(href, {_method: 'post', remote: true, form_alias: form_alias, client: client}, function (data) {
        // console.log(data.outcome);
        if (data.outcome == 'success') {
          $('#new-client-popup .close.close-js').click();
          if ($('#bid_bond_contract_form_client_id').length > 0) {
            $('#bid_bond_contract_form_client_id').parent().parent().replaceWith(data.partial);
            $('#bid_bond_contract_form_client_id').selectpicker();
          } else if ($('#nwr_application_client_id').length > 0) {
            $('#nwr_application_client_id').parent().parent().replaceWith(data.partial);
            $('#nwr_application_client_id').selectpicker();
          }

        } else {
          $('#new-client-popup').replaceWith(data.partial);
          $('#new-client-popup').show();
          $('#client_client_category_id').selectpicker();
          create_client_remotely();
          close_element();
        }
      }, "json")
    });
  }

// prowizja
  function set_commission_for_bid_bond() {
    $('#bid_bond_contract_form_offer_duration').on('keyup', function (e, clickedIndex, isSelected, previousValue) {
      warranty_value = $('#bid_bond_contract_form_warranty_value').val().replaceAll(' ', '');
      offer_duration = $('#bid_bond_contract_form_offer_duration').val();
      if ((warranty_value != '') && (offer_duration != '')) {
        // calculate_commission_for_bid_bond();
        get_product_provision('bid_bond_contract_form');
        calculate_reward_value_for_bid_bond();
      }
    });
    $('#bid_bond_contract_form_warranty_value').focusout(function () {
      warranty_value = $('#bid_bond_contract_form_warranty_value').val().replaceAll(' ', '');
      offer_duration = $('#bid_bond_contract_form_offer_duration').val();
      if ((warranty_value != '') && (offer_duration != '')) {
        // calculate_commission_for_bid_bond();
        get_product_provision('bid_bond_contract_form');
        calculate_reward_value_for_bid_bond();
      }
    });
    $('#bid_bond_contract_form_express_warranty').change(function () {
      // calculate_commission_for_bid_bond();
      get_product_provision('bid_bond_contract_form', true);
    });
  }

// not used
  function calculate_commission_for_bid_bond() {
    warranty_value = $('#bid_bond_contract_form_warranty_value').val().replaceAll(' ', '');
    warranty_value = parseFloat(warranty_value);
    offer_duration = parseInt($('#bid_bond_contract_form_offer_duration').val().replaceAll(' ', '')) / 30;
    express = $('#bid_bond_contract_form_express_warranty').prop('checked');
    // commission_percentage_data = ;
    commission_percentage = parseFloat($('#bid_bond_contract_form_commission_value').data('percentage'));
    // commission_minimum = parseFloat($('#bid_bond_contract_form_commission_value').data('minimum'));
    commission_minimum = $('#bid_bond_contract_form_commission_value').data('minimum');
    value = warranty_value * commission_percentage;
    if (value < commission_minimum) {
      value = commission_minimum;
    }
    value = value * offer_duration;
    if (express) {
      value = value * 2;
    }
    // console.log(value);
    value = value.toFixed(2);
    // new_val = value.toFixed(2).replace(',','.');
    $('#bid_bond_contract_form_commission_value').val(value);
    fire_mask_for_currency('#bid_bond_contract_form_commission_value');
  }

  function set_reward_value_for_bid_bond() {
    $('.bid-bond-program-fee-js').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
      calculate_reward_value_for_bid_bond();
    });
  }

  // Wynagrodzenie MRFP liczymy tylko dla wadiow
  function calculate_reward_value_for_bid_bond() {

    if ($('#bid_bond_contract_form_commission_value').length == 0) {
      return;
    }
    // prowizja
    commission_value = $('#bid_bond_contract_form_commission_value').val().replaceAll(' ', '');
    id = $('#bid_bond_contract_form_program_id').val();
    fee_value = $('#bid_bond_contract_form_program_id option[value="' + id + '"]').data('fee');
    // console.log(fee_value);
    if ((fee_value != '') && (commission_value != '') && (fee_value != null)) {
      fee_value = parseInt(fee_value);
      commission_value = parseFloat(commission_value);
      value = parseFloat((fee_value * commission_value) / 100);
      value = value.toFixed(2);
    } else {
      value = 0.00;
    }
    $('#bid_bond_contract_form_reward_value').val(value);
    fire_mask_for_currency('#bid_bond_contract_form_reward_value');
  }

// bid bond package

  function clear_name_for_draft_status() {
    $('.status-clearing-bb-package-name-js').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
      if ($(this).val() == 'draft') {
        $('#bid_bond_package_name').val('');
      }
    });
  }

// statements
  function show_generate_statements_popup() {
    $('.statements-generate-btn-js').click(function (e) {
      e.preventDefault();
      $('#generate-statements').show();
    });
  }

  function showing_previous_statements() {
    $('.show-more-table-elements-js').click(function (e) {
      e.preventDefault();
      targets = $(this).data('target');
      $(targets).show();
      // TODO: nie widac efektu slide
      // TODO: podczas pokazywania/chowania strzalek wiersz skacze
      // $(targets).each( function() {
      //   $(this).slideDown();
      // });
      $(this).hide();
      $(this).parent().find('.hide-more-table-elements-js').show();
    });
    $('.hide-more-table-elements-js').click(function (e) {
      e.preventDefault();
      targets = $(this).data('target');
      $(targets).hide();
      // TODO: nie widac efektu slide
      // TODO: podczas pokazywania/chowania strzalek wiersz skacze
      // $(targets).each( function() {
      //   $(this).slideUp();
      // });
      $(this).hide();
      $(this).parent().find('.show-more-table-elements-js').show();
    });
  }

  function statement_inputs_confirm_by_enter() {
    $('.let-confirm-with-enter-js').keypress(function (event) {
      target = $(this).data('target');
      var keycode = (event.keyCode ? event.keyCode : event.which);
      if (keycode == 13) {
        $(target).click();
      }
      event.stopPropagation();
    });
  }

  function confirming_statement_fields() {
    $('.statement-confirm-js').click(function (e) {
      e.preventDefault();
      target = $(this).data('target');
      input = $(this).data('input');
      index = $(this).data('index');
      // parsing data from inputs
      if ($(input).data('type') == 'number') {
        val = $(input).val();

        // parsed_val = val.replace(" ", "").replace(",", ".")
        // decimal = parseFloat(parsed_val || "0.0").toLocaleString('pl', { minimumFractionDigits: 2 })
        // console.log('decimal: ', decimal)
        // $(input).siblings('.link').text(decimal);

        val = val || "0.0";
        parts_of_value = val.includes('.') ? val.split('.') : val.split(',');
        if (parts_of_value[0].length > 3) {
          rest = parts_of_value[0].length % 3;
          if (parts_of_value[1]) {
            decimal = parts_of_value[1].length < 2 ? parts_of_value[1] + '0' : parts_of_value[1];
          } else {
            decimal = '00';
          }
          prep_value = parts_of_value[0].substring(0, rest) + ' ' + parts_of_value[0].substring(rest).match(/.{1,3}/g).join(' ') + ',' + decimal;
          $(input).siblings('.link').text(prep_value);
        } else {

          decimal = parts_of_value[1].length < 2 ? parts_of_value[1] + '0' : parts_of_value[1];
          prep_value = parts_of_value[0] + ',' + decimal;
          $(input).siblings('.link').text(prep_value);
        }

      } else if ($(input).data('type') == 'risk') {
        prep_value = [null, 'I', 'II', 'III', 'IV', 'V'][$(input).val()];
        $(input).siblings('.link').text(prep_value);
      }
      // toggle for checkboxes
      if ($(target).val() == 'true') {
        $(target).val('false');
        $(this).removeClass('success').addClass('primary');
      } else {
        $(target).val('true');
        input = $(this).data('input');
        index = $(this).data('index');
        if (!$(input).hasClass('hidden')) {
          $(input).addClass('hidden');
          $(input).siblings('.link').show();
        }
        $(this).removeClass('primary').addClass('success');
      }
      check_if_all_confirmations_are_true(index);
    });

    // $('.contract-close-confirm-js').click( function(e) {
    //   // TODO: not used
    //   e.preventDefault();
    //   target = $(this).data('target');
    //   // index = $(this).data('index');
    //   if ($(target).val() == 'true') {
    //     $(target).val('false');
    //     $(this).removeClass('danger').addClass('primary');
    //   } else {
    //     $(target).val('true');
    //     $(this).removeClass('primary').addClass('danger');
    //   }
    // });
  }

  function check_if_all_confirmations_are_true(index) {
    all_confirmations = $('.hidden-confirm-js-' + index)
    all_values = all_confirmations.map(function () {
      return $(this).val()
    }).get();
    if (!all_values.includes('false')) {
      $('.update-statement-' + index + '-js').removeAttr('disabled');
      $('.update-statement-' + index + '-js').removeClass('disabled');
    } else {
      $('.update-statement-' + index + '-js').attr('disabled', 'disabled');
      $('.update-statement-' + index + '-js').addClass('disabled');
    }
  }

  function show_statement_input() {
    $('.statement-show-input-js').click(function (e) {
      e.preventDefault();
      target = $(this).data('target');
      $(this).hide();
      $(target).removeClass('hidden');
      checkbox = $(this).parent().siblings('.statement-confirm-js');
      if (checkbox.hasClass('success')) {
        checkbox.click();
      }
    });
  }

  function update_statement_with_send_params() {
    $('.update-statement-js').click(function (e) {
      e.preventDefault();
      if ($(this).hasClass('disabled')) {
      } else {
        href = $(this).attr('href');
        target_list = $(this).data('target');
        targets = $(target_list);
        index = $(this).data('index');
        removable = "_" + index.toString();
        params = targets.map(function () {
          return ($(this).attr('id').replace(removable, '') + '=' + $(this).val());
        }).get().join('&');
        if (href.includes('?')) {
          new_href = href + '&' + params + '&index=' + index;
        } else {
          new_href = href + '?' + params + '&index=' + index;
        }
        $.post(new_href, {_method: 'patch', remote: true}, function (data) {
          $('.table-row-' + data.index).replaceWith(data.partial);
          showing_previous_statements();
          get_edition_row_for_statement();
        }, "json");
        // $.post(new_href, {_method:'patch', remote: true}, null, "script");
      }
    });
  }

  function get_edition_row_for_statement() {
    $(".edit-statement-js").on('click', function (e) {
      e.preventDefault();
      href = $(this).attr('href');
      $.get(href, {_method: 'get', remote: true}, function (data) {
        $('.table-row-' + data.index).replaceWith(data.partial);
        mask_for_currencies();
        showing_previous_statements();
        confirming_statement_fields();
        show_statement_input();
        statement_inputs_confirm_by_enter();
        update_statement_with_send_params();
        check_if_all_confirmations_are_true(data.index);
      }, "json");
    });
  }

// case_note
  function case_note_filling_dates() {
    if ($('#case_note_receipt_date').length > 0) {
      $('#case_note_receipt_date').datepicker().on('changeDate', function (e) {
        if ($('#case_note_deadline_date').val() == '') {
          date = $(this).val();
          days = 30;
          deadline = add_days_to_date(date, days);
          $('#case_note_deadline_date').datepicker('update', deadline);
        }
        if ($('#case_note_reminder_date').val() == '') {
          date = $(this).val();
          days = 23;
          deadline = add_days_to_date(date, days);
          $('#case_note_reminder_date').datepicker('update', deadline);
        }
      });
    }
  }

// work_memo
  function work_memo_text_fields_inputs_insert() {
    $('.work-memo-toggle-js').each(function () {
      if ($(this).find('.no-appendix').lenght > 0) {
        // horizontal form
        $(this).find('.no-appendix').prepend("<input type='checkbox' class='work-memo-toggle-input work-memo-toggle-input-js'>");

      } else {
        // simple form
        $(this).prepend("<input type='checkbox' class='work-memo-toggle-input work-memo-toggle-input-js'>");
      }
      if ($(this).find('textarea').val() == '') {
        $(this).find('.work-memo-toggle-input-js').prop('checked', false);
      } else {
        $(this).find('.work-memo-toggle-input-js').prop('checked', true);
        $(this).find('.work-memo-toggle-input-js').addClass('checked');
        $(this).find('textarea').show();
      }

    });
    work_memo_inputs_toggler();
  }

  function work_memo_inputs_toggler() {
    $('.work-memo-toggle-input-js').click(function (e) {
      if ($(this).siblings('textarea').length > 0) {
        textarea = $(this).siblings('textarea');
      } else {
        textarea = $(this).siblings().find('textarea')
      }
      ;
      if ($(this).hasClass('checked')) {
        $(this).prop('checked', false);
        $(this).removeClass('checked');
        value = textarea.val();
        textarea.data('value', value);
        textarea.val('');
        textarea.slideUp();
        // value = $(this).siblings('textarea').val();
        // $(this).siblings('textarea').data('value', value);
        // $(this).siblings('textarea').val('');
        // $(this).siblings('textarea').slideUp();
      } else {
        $(this).prop('checked', true);
        $(this).addClass('checked');
        value = textarea.data('value');
        textarea.val(value);
        textarea.slideDown();
        // value = $(this).siblings('textarea').data('value');
        // $(this).siblings('textarea').val(value);
        // $(this).siblings('textarea').slideDown();
      }
    });

    $('.work-memo-toggle label').click(function (e) {
      $(this).siblings('.work-memo-toggle-input-js').click();
    });
  }

  function work_memo_definition_select() {
    $('#work_memo_work_memo_definitions').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
      if ($(this).val() == '') {
        // do nothing
      } else {
        html = $('#work-memo-item-draft').html();
        number = $('.work-memo-items-js .offset-1').length;
        html = html.replaceAll('NUMBER', number);
        html = html.replaceAll('HEADER', $('#work_memo_work_memo_definitions').val());
        $('.work-memo-items-js').append(html);
        $(this).val('');
        $(this).selectpicker('render');
        work_memo_item_removing();
      }
    });
  }

  function work_memo_item_header() {
    $('.header-swap-js').each(function () {
      value = $(this).find('input').val();
      $(this).siblings('.work_memo_work_memo_items_body').find('label').text(value);
      $(this).hide();
    });
  }

  function work_memo_item_trash_button() {
    $('.work-memo-item-js').each(function () {
      html = $('#trash-ico').html();
      if ($(this).find('.remove-work-memo-item-js').length == 0) {
        $(this).prepend(html);
      }
      work_memo_item_removing();
    });
  }

  function work_memo_item_removing() {
    $('.remove-work-memo-item-js').click(function (e) {
      e.preventDefault();
      $(this).parent().hide();
      $(this).siblings().find('input').val('');
      $(this).siblings().find('textarea').val('');
    });
  }

// reports

  function report_select() {
    $('#report-picker-js .selectpicker').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
      e.preventDefault()
      $(this).closest('form').submit();
    });
  }


// dictionaries

  function adding_contributions_in_program() {
    $('.program-to-product-js').each(function (index) {
      html = $('#contribution-template').html().replaceAll('NUMBER', index);
      id = $(this).data('contribution');
      if (id == undefined) {
        html = html.replace('CONTRIBUTION_ID', '');
      } else {
        html = html.replace('CONTRIBUTION_ID', id);
      }
      $(this).parent().append(html);

      if ($(this).prop('checked')) {
        $(this).addClass('checked');
        $(this).siblings('.contribution').show();
      }
    });

    $('.program-to-product-js').click(function (e) {
      if ($(this).hasClass('checked')) {
        $(this).removeClass('checked');
        $(this).siblings('.contribution').hide();
      } else {
        $(this).addClass('checked');
        $(this).siblings('.contribution').show();
      }
    });
  }

  function create_contrbutions_hash() {
    $('.inputs-set input').focusout(function () {
      contribution_box = $(this).parent().parent().parent().parent().parent();
      hash = '{';
      contribution_box.find('.inputs-set').each(function (index) {
        min = 'min: "' + $(this).find('.minimum-js').val() + '", ';
        max = 'max: "' + $(this).find('.maximum-js').val() + '", ';
        percent = 'percent: "' + $(this).find('.percent-js').val() + '"';
        hash = hash + index + ': {' + min + max + percent + '},';
      });
      hash = hash + '}';
      hash = hash.replace('},}', '}}');
      contribution_box.find('textarea').text(hash);
    });
  }

  function add_new_thershold() {
    $('.add-threshold-js').click(function (e) {
      e.preventDefault();
      html = $('#contribution-template .inputs-set').html();
      new_html = '<div class="row inputs-set">' + html + '</div>';
      $(this).siblings('.input-group').append(new_html);
      create_contrbutions_hash();
    });
  }

  function contribution_fields() {
    $('.add-new-contribution-js').click(function (e) {
      e.preventDefault();
      link = $(this);
      html = $('#contribution-template-new').html();
      html = html.replace('{{prd-id}}', link.data('prd-id'));
      html = html.split('{{NUM}}').join(link.data('prd-index'));
      link.parent().append(html);
    });

    $('.form-group').on('click', '.add-contrib-item-js', function (e) {
      e.preventDefault();
      link = $(this);
      html = $('#contribution-template-new .contrib-items').html();
      html = html.split('{{NUM}}').join(link.data('prd-index'));

      rows_num = $(this).siblings('.contrib-items').find('.inputs-set').length;
      html = html.split('contrib_items_attributes][0]').join('contrib_items_attributes][' + rows_num + ']');
      $(this).siblings('.contrib-items').append(html);
    })
  }

  function provisions_showing_months() {
    if ($('#provision_item_period_dependent_provision').length > 0) {
      $('#provision_item_period_dependent_provision').click(function (e) {
        if ($(this).hasClass('checked')) {
          $(this).removeClass('checked');
          from_val = $('#provision_item_months_from').val();
          to_val = $('#provision_item_months_to').val();
          $('#provision_item_months_from').data('value', from_val);
          $('#provision_item_months_to').data('value', to_val);
          $('.months-toggler-js').hide();
        } else {
          $(this).addClass('checked');
          from_val = $('#provision_item_months_from').data('value');
          to_val = $('#provision_item_months_to').data('value');
          $('#provision_item_months_from').val(from_val);
          $('#provision_item_months_to').val(to_val);
          $('.months-toggler-js').show();
        }
      });

      // on reaload
      if ($('#provision_item_period_dependent_provision').prop('checked')) {
        $('#provision_item_period_dependent_provision').addClass('checked');
        $('.months-toggler-js').show();
      } else {
        $('.months-toggler-js').hide();
      }
    }
  }
